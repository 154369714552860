//This file is automatically rebuilt by the Cesium build process.
export default "struct czm_material\n\
{\n\
vec3 diffuse;\n\
float specular;\n\
float shininess;\n\
vec3 normal;\n\
vec3 emission;\n\
float alpha;\n\
};\n\
";
