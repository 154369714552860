/**
 * @private
 */
const TileEdge = {
  WEST: 0,
  NORTH: 1,
  EAST: 2,
  SOUTH: 3,
  NORTHWEST: 4,
  NORTHEAST: 5,
  SOUTHWEST: 6,
  SOUTHEAST: 7,
};
export default TileEdge;
