//This file is automatically rebuilt by the Cesium build process.
export default "float czm_round(float value) {\n\
return floor(value + 0.5);\n\
}\n\
vec2 czm_round(vec2 value) {\n\
return floor(value + 0.5);\n\
}\n\
vec3 czm_round(vec3 value) {\n\
return floor(value + 0.5);\n\
}\n\
vec4 czm_round(vec4 value) {\n\
return floor(value + 0.5);\n\
}\n\
";
